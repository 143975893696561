<template>
  <v-card class="mb-7" elevation="0" outlined tile>
    <v-card-text class="pa-5">
      <div>
        <v-data-table
          :headers="headers"
          :items="listado"
          dense
          class="border child-aux"
          multi-sort
          :mobile-breakpoint="0"
          :items-per-page="-1"
          no-results-text="No se encontraron datos"
          no-data-text="No se encontraron datos"
          :search="search"
          :disable-pagination="true"
          :hide-default-footer="true"
          item-class="class"
          :fixed-header="true"
          :height="height"
        >
          <template v-slot:top>
            <v-toolbar flat dense class="py-0 d-block">
              <v-toolbar-title class="black--text w-50" style="font-size: 18px;"
                >DETALLE REAL POR ÁREA Y CECO</v-toolbar-title
              >
              <v-divider class="mx-4" vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                class="text-field-search-matriz white--text"
                color="white"
                background-color="rgb(220, 220, 220, 0.16)"
                rounded
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
            <v-divider></v-divider>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <div class="w-100 d-flex justify-space-between pl-2 pr-5 font-weight-bold">
              <div class="d-flex" style="font-size: 14px;">TOTAL</div>
              <div style="font-size: 16px;">{{ total }}</div>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'G9DetalleRealXAreaYCeco',
  props: {
    datos: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    async datos(val) {
      this.listado = val[0].detalle;
      this.total = val[0].formattotal;
    }
  },
  data: () => ({
    height: 400,
    search: '',
    headers: [
      { text: 'EMPRESA', value: 'sede', width: '160', class: 'primary' },
      { text: 'ÁREA', value: 'subarea', width: '260', class: 'primary' },
      { text: 'CECO', value: 'idceco', width: '160', class: 'primary' },
      { text: 'CUENTA MAYOR', value: 'cuentamayor', width: '160', class: 'primary' },
      { text: 'DETALLE GASTO', value: 'descripciondetallada', width: '300', class: 'primary' },
      { text: 'GASTO', value: 'formatmonto', width: '160', class: 'primary', align: 'right' }
    ],
    listado: [],
    total: null
  }),
  components: {}
};
</script>

<style lang="scss">
.header-items-fixed-ind {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.header-items-fixed-ind.nth-child1 {
  left: 0;
  z-index: 4 !important;
}
.child-aux .header-items-fixed-ind.nth-child2 {
  top: 0;
  left: 155px !important;
  border-right: thin solid rgba(250, 250, 250) !important;
  z-index: 4 !important;
}
.class-items-fixed-ind td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 3;
}
.child-aux .class-items-fixed-ind td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 155px !important;
  z-index: 3;
}
.cell-d-none td:last-child {
  display: none;
}
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 3;
  color: white !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: white;
  color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.7);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: #1e1e1e;
  color: white;
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.text-field-search-matriz.theme--light.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}
.text-field-search-matriz.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--dark.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 10px;
}
</style>
